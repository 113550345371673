import * as React from 'react';

import { Link } from 'gatsby';
import { Layout } from '../components';

// markup
const NotFoundPage = () => {
	return (
		<Layout>
			<title>Not found</title>

			<main data-name="layout" className="container-fluid">
				<div className="main py-5 row justify-content-center align-items-center">
					<div className="col-12">

						<h1 className="pb-4">Page not found</h1>
						<p>
							Sorry{" "}
							<span role="img" aria-label="Pensive emoji">😔</span>{" "}
							we couldn’t find what you were looking for.<br />
							<Link to="/">Go home</Link>.
						</p>

					</div>
				</div>
			</main>

		</Layout>
	);
};

export default NotFoundPage;
